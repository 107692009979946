<template>
  <el-container class="xa-container">
    <el-header height="40px">
      <titleFilterBarHead
        :isLoading="isLoading"
        :parent="parent"
        :title="title"
      />
    </el-header>
    <el-main>
      <el-form
        v-loading="isLoading"
        class="xa-form__wrap"
        ref="form"
        label-position="left"
        :rules="rules"
        :model="form"
        label-width="160px"
      >
        <el-form-item label="集合名称" prop="name"
          ><el-input v-model="form.name" placeholder="请输入集合名称"></el-input
        ></el-form-item>
        <el-form-item label="关联产品" prop="products">
          <div class="products-box">
            <el-table :data="form.products" style="width: 100%">
              <el-table-column type="index" width="50" />
              <el-table-column prop="sku_name" label="关联名称">
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row.sku_name"
                    placeholder="输入关联名称"
                    size="mini"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="title" label="产品链接">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.title }}
                    <el-button
                      v-if="scope.row.title"
                      type="text"
                      @click="onEdit(scope.$index)"
                      >编辑</el-button
                    >
                    <el-button v-else type="text" @click="onEdit(scope.$index)"
                      >选择</el-button
                    >
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="120">
                <template slot-scope="scope">
                  <el-button
                    @click="onDeleteProducts(scope.$index, scope.row)"
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    circle
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="products-add-btn">
              <el-button type="primary" size="small" @click="addNewSku" round
                >添加更多关联商品</el-button
              >
            </div>
          </div>
        </el-form-item>
      </el-form>
    </el-main>
    <el-footer height="40px" class="xa-cell" style="border-top:1px solid #e4e4e4;">
      <el-button type="primary" size="mini" @click="onSubmit">保存</el-button>
      <el-button size="mini" @click="$router.go(-1)">取消</el-button>
    </el-footer>
    <el-dialog
      class="product-dialog"
      title="选择产品"
      :visible.sync="isShowProductTable"
    >
      <productTable
        v-show="isShowProductTable"
        :multipSelect="false"
        :params="productTableParams"
        @close="isShowProductTable = false"
        @submit="onAddProduct"
      />
    </el-dialog>
  </el-container>
</template>
<script>
import recodeLastPath from '@/controllers/recodeLastPath'
import { saveSku, getSku, deleteSku } from '@target/apis/mall'
const storageKey = 'MALL_SKUFORM_PARENT'
export default {
  components: {
    titleFilterBarHead: () => import('@/components/comHead/FilterBarHead'),
    productTable: () => import('@target/views/mall/productTable')
  },
  data() {
    return {
      title: 'SKU集合',
      isShowProductTable: false,
      productTableParams: {
        is_sku: 1
      },
      isLoading: false,
      curEditIndex: -1,
      parent: {
        // 上一个页面得到信息
        path: '',
        title: ''
      },
      form: {
        guid: '',
        name: '',
        products: []
      },
      rules: {
        name: [{ required: true, message: '请输入集合名称', trigger: 'blur' }],
        products: [
          {
            type: 'array',
            required: true,
            message: '请选择下产品'
          }
        ]
      }
    }
  },
  methods: {
    addNewSku() {
      this.form.products.push({
        title: '',
        sku_name: '',
        guid: '',
        _isFE: true
      })
    },
    onAddProduct(product) {
      const result = this.form.products.find(item => {
        return item.guid === product.guid
      })
      if (result) {
        this.$message.error('该商品已经关联过，不能再关联！')
      } else {
        this.form.products[this.curEditIndex].guid = product.guid
        this.form.products[this.curEditIndex].title = product.title
      }
    },
    onEdit(index) {
      this.curEditIndex = index
      this.isShowProductTable = true
    },
    async onDeleteProducts(index, item) {
      await this.$confirm('是否要删除该条数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (this.form.guid && !item._isFE) {
        try {
          this.isLoading = true
          await this.$actionWithConfirm(
            deleteSku({
              guid: item.guid,
              sku_guid: this.form.guid
            })
          )
        } finally {
          this.isLoading = false
        }
      }
      this.form.products.splice(index, 1)
    },
    checkProducts() {
      let result = true
      this.form.products.forEach(item => {
        if (!item.title || !item.guid) {
          result = false
        }
      })
      return result
    },
    async onSubmit() {
      let errorMsg = ''
      if (!this.form.name) {
        errorMsg = '请填写集合名称'
      } else if (this.form.products.length === 0) {
        errorMsg = '请关联商品'
      } else if (this.checkProducts() === false) {
        errorMsg = '有关联商品没有关联名称或产品链接，请完善或删除！'
      }
      if (errorMsg) {
        this.$message.error(errorMsg)
      } else {
        this.isLoading = true
        await this.$actionWithConfirm(saveSku(this.form))
        this.$message.success('保存成功')
        this.isLoading = false
        this.$router.go(-1)
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    /* 如果是导航点击的跳转地址 着直接打开 */
    if (to.query.nav) {
      next()
    } else {
      next(vm => {
        const parent = recodeLastPath(from, vm, storageKey)
        if (parent) vm.parent = parent
      })
    }
  },
  async mounted() {
    if (this.$route.query.params && this.$route.query.params) {
      let params = JSON.parse(this.$route.query.params)
      this.isLoading = true
      const data = await this.$actionWithConfirm(getSku(params))
      Object.assign(this.form, data)
      this.isLoading = false
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-dialog__body {
  padding-top: 0;
  padding-bottom: 0;
}
.xa-form__wrap {
  padding: 32px;
  max-width: 960px;
}
.products-add-btn {
  display: flex;
  margin-top: 16px;
  justify-content: center;
}
</style>
